import React from 'react';

const OrdersTableDateHeader = ({ date }: { date: string }) => {
  return (
    <div className="sm:flex sm:items-center">
      <div className="sm:flex-auto">
        <h1 className="text-xl font-semibold text-gray-900">{date}</h1>
      </div>
    </div>
  );
};

export { OrdersTableDateHeader };

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { OrderType, PickerTypeEdge } from '../../../generated/graphql';
import { ToggleSwitch } from '../../UI/toggle-switch/ToggleSwitch';
import { OrdersTablePaymentButton } from '../ordersTablePaymentButton/OrdersTablePaymentButton';
import { OrdersTableDeliveryStatus } from '../ordersTableDeliveryStatus/OrdersTableDeliveryStatus';
import { cashPaymentCode } from '../../../utils/utils';

interface IOrdersTableItemRow {
  item: OrderType;
  pickers: PickerTypeEdge[] | undefined;
  appointPicker?: (pickerId: string | null, orderNumber: string) => void;
  openPaymentModal: any;
}

const OrdersTableItemRow: React.FC<IOrdersTableItemRow> = ({ item, pickers, appointPicker, openPaymentModal }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <tr
      key={item.orderNumber}
      onClick={() => navigate(`/orders/${item.id}`)}
      className="divide-x divide-gray-200 hover:bg-yellow-100 active:bg-yellow-100 focus:bg-yellow-100 cursor-pointer"
    >
      <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        {item.orderNumber}
        <dl className="lg:hidden mt-3">
          <dt className="font-normal">{t('Client full name')}:</dt>
          <dd className="mt-1 font-medium text-gray-900">{item.customerFullname}</dd>
        </dl>
      </td>
      <td className="hidden xl:table-cell px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
        {moment(item.createdAt).format('DD-MM-YYYY HH:mm')}
      </td>
      <td className="hidden 2xl:table-cell px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
        {moment(item.deliveryDate).format('DD-MM-YYYY')}
      </td>
      <td className="hidden 2xl:table-cell px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
        {`
        ${moment(`${item.deliveryDate} ${item.deliveryIntervalStart}`).format('HH:mm')}
        -
        ${moment(`${item.deliveryDate} ${item.deliveryIntervalEnd}`).format('HH:mm')}
      `}
      </td>
      <td className="font-medium text-gray-900 px-3 py-4 text-sm text-gray-500">
        <select
          id={String(item.id)}
          name="select"
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => {
            if (appointPicker) {
              appointPicker(e?.target?.value === '' ? null : e?.target?.value, String(item.orderNumber));
            }
          }}
          defaultValue={item.activeAssembly?.picker?.id || 'none'}
          className="block w-full text-base border-yellow-300 focus:outline-none focus:ring-yellow-500 focus:border-yellow-500 sm:text-sm rounded-md"
        >
          <option value="">{t('Assign a picker')}</option>
          {pickers?.map((option) => {
            return (
              <option value={option?.node?.id} key={option?.node?.id}>
                {option?.node?.user.firstName}
              </option>
            );
          })}
        </select>
      </td>
      <td className="font-medium text-center text-gray-900 px-3 py-4 text-sm text-gray-500">
        <div className="mx-auto inline-block">
          <ToggleSwitch
            pickerValue={item.activeAssembly?.picker?.id}
            orderId={item.id}
            activeRating={item.activeAssembly?.rating ?? 0}
          />
        </div>
      </td>
      <td
        style={{ wordBreak: 'break-word' }}
        className="hidden lg:table-cell font-medium text-gray-900 px-3 py-4 text-sm text-gray-500"
      >
        {item.customerFullname}
      </td>
      <td className="font-medium text-center text-gray-900 px-3 py-4 text-sm text-gray-500 whitespace-nowrap">
        <OrdersTableDeliveryStatus deliveryStatus={item.deliveryStatus} />
      </td>
      <td className="font-medium text-center text-gray-900 px-3 py-4 text-sm text-gray-500">
        <OrdersTablePaymentButton
          paymentStatus={
            item.paymentMethod.code === cashPaymentCode ? 'CASH' : item.orderpaymentSet?.edges[0]?.node?.status
          }
          handleClick={openPaymentModal}
        />
      </td>
    </tr>
  );
};

export { OrdersTableItemRow };

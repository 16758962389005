import React, { useCallback } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';

interface IPagination {
  toPage: (page: number) => void;
  rangeStart: number;
  rangeEnd: number;
  activePage: number;
  lastPage: number;
  totalCount: number;
}

const Pagination: React.FC<IPagination> = ({ lastPage, activePage, rangeStart, rangeEnd, toPage, totalCount }) => {
  const nextPage = useCallback(() => {
    if (activePage !== lastPage) {
      toPage(activePage + 1);
    }
  }, [toPage, activePage, lastPage]);

  const prevPage = useCallback(() => {
    if (activePage !== 1) {
      toPage(activePage - 1);
    }
  }, [toPage, activePage]);

  return (
    <nav className="md:rounded-lg mb-5 bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
      <div className="hidden sm:block">
        <p className="text-sm text-gray-700">
          <span className="font-medium">{rangeStart}</span>-<span className="font-medium">{rangeEnd}</span>
          &nbsp; из &nbsp;
          <span className="font-medium">{totalCount}</span>
        </p>
      </div>
      <div className="flex-1 flex justify-between sm:justify-end">
        <span
          onClick={prevPage}
          className={`${
            activePage !== 1 ? 'cursor-pointer hover:bg-gray-50' : 'opacity-50'
          } mr-2 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white`}
        >
          <ChevronLeftIcon className="h-5 w-5" />
        </span>
        <span className="mr-2 rounded-md bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium">
          {activePage}
        </span>
        <span
          onClick={nextPage}
          className={`${
            activePage !== lastPage ? 'cursor-pointer hover:bg-gray-50' : 'opacity-50'
          } relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white`}
        >
          <ChevronRightIcon className="h-5 w-5" />
        </span>
      </div>
    </nav>
  );
};

export { Pagination };

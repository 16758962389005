import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Dialog, Transition } from '@headlessui/react';
import { ChatIcon, XIcon, RefreshIcon } from '@heroicons/react/outline';

import { GetSberPaymentsFormLinkMutationVariables } from '../../generated/graphql';
import { getPaymentStatus, successToast } from '../../utils/utils';
import { PaymentModalData } from '../../pages/orders/Orders';

interface IPaymentLinkModal extends PaymentModalData {
  isOpen: boolean;
  onClose: () => void;
  getPaymentLink: (variables: GetSberPaymentsFormLinkMutationVariables) => void;
}

const PaymentLinkModal: React.FC<IPaymentLinkModal> = ({
  orderNumber,
  amount: amountProp,
  customerPhone,
  status,
  isOpen,
  onClose,
  getPaymentLink,
  paymentLink,
}) => {
  const { t } = useTranslation();
  const [amount, setAmount] = useState(amountProp || 0);

  useEffect(() => {
    setAmount(amountProp);
  }, [amountProp]);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" open={isOpen} className="relative z-[50]" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
                    onClick={onClose}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <Dialog.Title as="h3" className="text-lg font-medium leading-6 mb-3 text-gray-900">
                  {t('Link for payment')}
                </Dialog.Title>

                <div className="grid">
                  <div className="mb-3">
                    <label htmlFor="amount" className="block text-sm font-medium text-gray-700">
                      {t('Amount')}
                    </label>
                    <div className="mt-1 flex gap-4 items-center justify-between">
                      <div className="w-4/12">
                        <input
                          type="number"
                          min={0}
                          value={amount}
                          onChange={(e) => {
                            setAmount(Number(e?.target?.value));
                          }}
                          name="amount"
                          id="amount"
                          className="inline-block grow shadow-sm focus:ring-yellow-300 focus:border-yellow-300 w-full sm:text-sm border-gray-300 rounded-md"
                          placeholder=""
                        />
                      </div>
                      <div>
                        <button
                          disabled={!paymentLink}
                          type="button"
                          onClick={() => {
                            if (paymentLink) {
                              const phone = customerPhone.replace(/\D+/g, '');
                              window?.open(`https://wa.me/${phone}?text=${paymentLink}`, '_blank')?.focus();
                            }
                          }}
                          className={`mr-3 inline-block bg-yellow-400 text-white font-bold p-2 rounded-full
                            ${!paymentLink ? 'disabled:opacity-50' : 'hover:bg-yellow-500 focus:outline-none'}`}
                        >
                          <ChatIcon className="w-6 h-6" />
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            getPaymentLink({
                              paymentAmount: amount,
                              orderNumber: String(orderNumber),
                            });
                          }}
                          className="inline-block bg-yellow-400 hover:bg-yellow-500 focus:outline-none text-white font-bold p-2 rounded-full"
                        >
                          <RefreshIcon className="w-6 h-6" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="mb-3">
                    <label htmlFor="totalCount" className="block text-sm font-medium text-gray-700">
                      {t('Generated link')}
                    </label>
                    <div className="mt-1 flex gap-4 items-center">
                      <input
                        onDoubleClick={() => {
                          if (paymentLink) {
                            navigator.clipboard.writeText(paymentLink);
                            successToast(t('Copied to clipboard'));
                          }
                        }}
                        readOnly
                        disabled={!paymentLink}
                        type="text"
                        defaultValue={paymentLink}
                        name="payment_link"
                        id="payment_link"
                        className="shadow-sm focus:ring-yellow-300 focus:border-yellow-300 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder=""
                      />
                      <button
                        disabled={!paymentLink}
                        onClick={() => {
                          if (paymentLink) {
                            navigator.clipboard.writeText(paymentLink);
                            successToast(t('Copied to clipboard'));
                          }
                        }}
                        type="button"
                        className={`inline-block bg-yellow-400 text-white font-bold p-2 rounded-full ${
                          !paymentLink ? 'disabled:opacity-50' : 'hover:bg-yellow-500 focus:outline-none'
                        }`}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  {!!paymentLink && (
                    <div className="flex items-center">
                      <label htmlFor="totalCount" className="block text-sm font-medium text-gray-700">
                        {t('Payment status')}:
                      </label>
                      <div className="mt-1 flex gap-4 items-center">
                        <div className={`whitespace-normal px-2 py-1 text-sm uppercase text-black `}>
                          <div
                            className={`px-2.5 py-0.5 inline-block rounded ${`paymentStatus--${
                              status || 'WAITING_PAYMENT'
                            }`}`}
                          >
                            <span className="font-semibold">{t(`${getPaymentStatus(String(status))}`)}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export { PaymentLinkModal };

import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router';
import { ToastContainer } from 'react-toastify';
import { SigninNumberInput } from '../../components/signin/SigninNumberInput';
import { useTokenAuthMutation } from '../../generated/graphql';
import { useAuth } from '../../contexts/auth-context';

const SigninPage: React.FC = () => {
  useEffect(() => {
    localStorage.clear();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const authContext = useAuth();
  const [tokenAuth] = useTokenAuthMutation({
    onCompleted: (tokenData) => {
      authContext.login(tokenData);
    },
    fetchPolicy: 'no-cache',
  });

  async function handleAuthorization({ email, password }: { email: string; password: string }) {
    try {
      await tokenAuth({
        variables: {
          email,
          password,
        },
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  }
  return (
    <div className="bg-gray-100 h-screen">
      <ToastContainer />
      <Routes>
        <Route path="*" element={<SigninNumberInput handleAuthorization={handleAuthorization} />} />
      </Routes>
    </div>
  );
};

export { SigninPage };

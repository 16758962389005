import { ApolloClient, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
// import { WebSocketLink } from '@apollo/client/link/ws';
// import { getMainDefinition } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';
import { t } from 'i18next';
import { errorToast } from './utils/utils';

const endpoint1 = createUploadLink({ uri: process.env.REACT_APP_API_URL });

// const endpoint2 = new HttpLink({
//   // uri: "https://glowing-stingray-38.hasura.app/v1/graphql", // use https for secure endpoint
//   uri: 'http://localhost:8080/v1/graphql', // use https for secure endpoint
//   // headers: {
//   //   'x-hasura-admin-secret': 'CaTFcmyG7HkQOftb0YLJBfjicD3WZ4S3ziU2VgVWiHn4s4kgmtw8bsac9fJFA8oP',
//   // }
// });

// Create a WebSocket link:
// const wsLink = new WebSocketLink({
//   // uri: "ws://glowing-stingray-38.hasura.app/v1/graphql", // use wss for a secure endpoint
//   uri: 'ws://localhost:8080/v1/graphql', // use wss for a secure endpoint
//   options: {
//     reconnect: true,
//     // connectionParams: {
//     //   headers: {
//     //     'x-hasura-admin-secret': 'CaTFcmyG7HkQOftb0YLJBfjicD3WZ4S3ziU2VgVWiHn4s4kgmtw8bsac9fJFA8oP'
//     //   }
//     // }
//   },
// });

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
// const link = split(
//   // split based on operation type
//   ({ query }) => {
//     const definition = getMainDefinition(query);
//     return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
//   },
//   // wsLink,
//   split((operation) => operation.getContext().clientName === 'chat', endpoint2, endpoint1)
// );
const link = endpoint1;

const asyncAuthLink = setContext(async (_: any, { headers }: any) => {
  // get the authentication token from local storage if it exists
  localStorage.getItem('token');
  const token = localStorage.getItem('token');

  return {
    headers: {
      ...headers,
      Authorization: token ? `Token ${token}` : '',
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => {
      if (message.includes('Security code is not valid')) {
        errorToast(t('Invalid authorization code.'));
      } else if (message.includes('User with this phone or email already exists')) {
        errorToast(t('User with this phone or email already exists. Perhaps you entered them wrong.'));
        /* TODO: this tries to parse JSON objects, but it can potentially get
            string with message like `Error decoding signature`. If that happens,
            then JSON.parse will throw an error. Need some workaround that.
        */
        // } else if (isInvalidFieldError(message)) {
        //   errorToast(t(parseInvalidInputError(message)));
      } else if (message.includes('Signature has expired')) {
        window.location.replace('/');
        localStorage.removeItem('token');
        localStorage.removeItem('user');
      } else {
        errorToast(t(message));
      }
    });
  }
  if (networkError) {
    // TODO: show error message (turn off server)
    // eslint-disable-next-line no-console
    errorToast(`${t('Network error')}: ${t(networkError.message)}`);
  }
});

const client = new ApolloClient({
  link: asyncAuthLink.concat(errorLink).concat(link),
  cache: new InMemoryCache(),
});

export { client };

import React, { createContext, useContext, useMemo } from 'react';
import { useBoardOrdersBagsDriversData } from './boardHooks';
import { BagData, ID, OrderData } from './boardTypes';
import {
  useAddOrderToBagMutation,
  useRemoveOrderFromBagMutation,
  useSwapOrderPriorityMutation,
} from '../../generated/graphql';

const POLL_INTERVAL = 10_000;

const BoardDataContext = createContext<{
  loading: boolean;
  bags: BagData[];
  orders: OrderData[];
  ordersWithoutBags: OrderData[];
  addOrderToBag: (orderId: ID, bagId: ID) => void;
  removeOrderFromBag: (orderId: ID) => void;
  swapOrderPriority: (order1: ID, order2: ID) => void;
}>(undefined!);

export const BoardDataProvider = ({ children }: any) => {
  const { loading: loadingData, bags, orders, ordersWithoutBags } = useBoardOrdersBagsDriversData(POLL_INTERVAL);
  const [addOrderToBagMutation, { loading: loadingAddMutation }] = useAddOrderToBagMutation();
  const [removeOrderFromBagMutation, { loading: loadingRemoveMutation }] = useRemoveOrderFromBagMutation();
  const [swapOrderPriorityMutation, { loading: loadingSwapOrderPriority }] = useSwapOrderPriorityMutation();

  const loading = useMemo(() => {
    return loadingData || loadingAddMutation || loadingRemoveMutation || loadingSwapOrderPriority;
  }, [loadingData, loadingAddMutation, loadingRemoveMutation, loadingSwapOrderPriority]);

  const value = useMemo(() => {
    async function addOrderToBag(orderId: ID, bagId: ID) {
      await addOrderToBagMutation({
        variables: {
          bagId,
          orderId,
        },
      });
    }

    async function removeOrderFromBag(orderId: ID) {
      await removeOrderFromBagMutation({
        variables: {
          orderId,
        },
      });
    }

    async function swapOrderPriority(order1: ID, order2: ID) {
      await swapOrderPriorityMutation({
        variables: {
          order1,
          order2,
        },
      });
    }

    return {
      loading,
      bags: bags ?? [],
      orders: orders ?? [],
      ordersWithoutBags: ordersWithoutBags ?? [],
      addOrderToBag,
      removeOrderFromBag,
      swapOrderPriority,
    };
  }, [
    loading,
    bags,
    orders,
    ordersWithoutBags,
    swapOrderPriorityMutation,
    addOrderToBagMutation,
    removeOrderFromBagMutation,
  ]);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <BoardDataContext.Provider value={value}>{children}</BoardDataContext.Provider>
  );
};

export const useBoardData = () => useContext(BoardDataContext);

import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Listbox, Transition } from '@headlessui/react';
import _ from 'lodash';
import { CheckIcon, ChevronDoubleDownIcon, QuestionMarkCircleIcon } from '@heroicons/react/solid';
import { DriverData } from '../../../providers/drivers/driversTypes';
import { VehicleVehicleType } from '../../../generated/graphql';

interface Props {
  drivers: DriverData[];
  value: DriverData | null;
  onChange?: (driver: DriverData | null) => void;
}

function VehicleIcon({ type }: { type: VehicleVehicleType }) {
  return (
    (
      {
        [VehicleVehicleType.A_1]: <i className="fa fa-car-side scale-125" />,
        [VehicleVehicleType.A_2]: <i className="fa fa-bicycle scale-150" />,
        // TODO:: find a way to do this without any
      } as any
    )[type] ?? <QuestionMarkCircleIcon className="h-5 w-5" aria-hidden="true" />
  );
}

function DriversDropdown({ drivers, value, onChange }: Props) {
  const { t } = useTranslation();

  function handleChange(driverId: DriverData['id']) {
    const selectedDriver = drivers.find(({ id }) => driverId === id);
    onChange?.(selectedDriver ?? null);
  }

  const orderedDrivers = useMemo(() => _.orderBy(drivers, 'vehicle.vehicleType'), [drivers]);

  return (
    <div className="">
      <Listbox value={value?.id ?? null} onChange={handleChange}>
        <div className="relative mt-1">
          <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
            <span className="block truncate">
              {value ? `${value?.user?.firstName} ${value?.user?.lastName}` : t('No driver')}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDoubleDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </span>
          </Listbox.Button>
          <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              <Listbox.Option
                className={({ active }) =>
                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                    active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                  }`
                }
                value={null}
              >
                <span>{t('No driver')}</span>
              </Listbox.Option>
              {orderedDrivers.map((driver) => (
                <Listbox.Option
                  key={driver.id}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                    }`
                  }
                  value={driver.id}
                >
                  {({ selected }) => (
                    <>
                      <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                        {`${driver.user.firstName} ${driver.user.lastName}`}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-400">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-400">
                          <VehicleIcon type={driver.vehicle?.vehicleType} />
                        </span>
                      )}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}

export { DriversDropdown };

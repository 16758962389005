import { useDriversQuery } from '../../generated/graphql';

export function useDriversDataHook() {
  const { data, loading } = useDriversQuery();

  const drivers = data?.drivers?.edges.map(({ node }) => node);

  return {
    loading,
    drivers,
  };
}

import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TokenAuthMutationVariables } from '../../generated/graphql';
import 'react-toastify/dist/ReactToastify.css';

interface Props {
  handleAuthorization: any;
}

const SigninNumberInput: React.FC<Props> = ({ handleAuthorization }) => {
  const { t } = useTranslation();

  const { handleSubmit, control, formState } = useForm<TokenAuthMutationVariables>({
    mode: 'onBlur',
    defaultValues: {
      email: '',
      password: '',
    },
  });

  return (
    <div className="bg-epihelperBg lg:bg-none min-h-full flex flex-col justify-center py-12 max-w-5xl md:mx-auto">
      <div className="mx-4 lg:max-h-96 lg:flex lg:flex-1 lg:rounded-lg lg:overflow-hidden lg:min-w-[992px] max-w-5xl">
        <div className="lg:bg-epihelper bg-cover w-4/6" />
        <div className="bg-white py-4 px-4 shadow rounded-lg lg:rounded-none lg:w-8/12 md:px-8 md:py-10">
          <h2 className="mb-6 text-left text-2xl font-bold text-gray-900">{t('Sign in to your account')}</h2>
          <form onSubmit={handleSubmit(handleAuthorization)}>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                {t('Email address')}:
              </label>
              <div className="mt-1">
                <Controller
                  render={({ field: { onChange, onBlur } }) => (
                    <input
                      className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${
                        formState.errors?.email && 'pr-10 border-red-500 placeholder-red-500'
                      }`}
                      onChange={(e) => onChange(e.target.value)}
                      onBlur={onBlur}
                      type="email"
                      inputMode="email"
                      placeholder={t('Enter your email')}
                    />
                  )}
                  control={control}
                  name="email"
                  rules={{
                    required: true,
                    pattern:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  }}
                />
              </div>

              <div className="mt-4">
                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                  {t('Password')}:
                </label>
                <div className="mt-1">
                  <Controller
                    render={({ field: { onChange, onBlur } }) => (
                      <input
                        className={`appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ${
                          formState.errors?.password && 'pr-10 border-red-500 placeholder-red-500'
                        }`}
                        type="password"
                        onChange={(e) => onChange(e.target.value)}
                        onBlur={onBlur}
                        inputMode="text"
                        placeholder={t('Enter your password')}
                      />
                    )}
                    control={control}
                    name="password"
                    rules={{
                      required: true,
                    }}
                  />
                </div>
              </div>

              <div className="mt-6 sm:flex sm:items-baseline">
                <button
                  disabled={!formState.errors}
                  type="submit"
                  className="w-full sm:w-auto sm:mr-4 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-violet-500 hover:bg-violet-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-violet-500"
                >
                  {t('Sign In')}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export { SigninNumberInput };

import React from 'react';

interface IOrdersTableHourRow {
  deliveryStartInterval: string;
  deliveryEndInterval: string;
}

const OrdersTableHourRow: React.FC<IOrdersTableHourRow> = ({ deliveryStartInterval, deliveryEndInterval }) => {
  return (
    <tr className="border-t border-gray-200">
      <th
        colSpan={9}
        scope="colgroup"
        className="bg-yellow-200 px-4 py-2 text-left text-sm font-semibold text-gray-900 sm:px-6"
      >
        {deliveryStartInterval} - {deliveryEndInterval}
      </th>
    </tr>
  );
};

export { OrdersTableHourRow };

import React from 'react';
import { useDrop } from 'react-dnd';
import { OrderBag } from '../order-bag/OrderBag';
import { BagData } from '../../../providers/board/boardTypes';
import { OrdersBagStatus } from '../../../generated/graphql';
import { DriverData } from '../../../providers/drivers/driversTypes';

interface Props {
  bag: BagData;
  drivers: DriverData[];
}

const DroppableOrderBag: React.FC<Props> = ({ bag, drivers }) => {
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: 'order',
    drop: () => ({ bag }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  const isActive = canDrop && isOver;

  return (
    <div
      ref={drop}
      className={`mb-2 shadow rounded-lg shrink-0 basis-full max-w-[210px] ${
        bag.activeOrdersBag?.status === OrdersBagStatus.InProgress ? 'opacity-50' : ''
      } `}
    >
      <OrderBag drivers={drivers} bag={bag} isActive={isActive} />
    </div>
  );
};

export { DroppableOrderBag };

import { useState } from 'react';
import { errorToast } from '../../../utils/utils';
import { usePickerData } from '../../../providers/orders/pickerDataContext';

interface ToggleSwitchProps {
  pickerValue: string | number;
  activeRating: number;
  orderId: string;
}

const ToggleSwitch = ({ orderId, activeRating, pickerValue }: ToggleSwitchProps) => {
  const values: number[] = [-1, 0, 1];
  const [selected, setSelected] = useState<number>(activeRating);
  const { changeAssembly: changeAssemblyRating } = usePickerData();

  const handleChangeRating = (rating: number) => {
    if (pickerValue) {
      if (rating) {
        setSelected(rating);
        changeAssemblyRating(orderId, rating);
      } else {
        errorToast('Choose another');
      }
    } else {
      errorToast('Select picker');
    }
  };

  const selectionStyle = () => {
    return {
      left: `${(values.indexOf(selected) / 3) * 100}%`,
      background: `${(selected === -1 && '#cf3434') || (selected === 0 && 'gray') || (selected === 1 && '#63c720')}`,
    };
  };

  return (
    <div className="relative w-[3.8rem] h-[1rem] bg-[#e4e4e4] rounded-full">
      {values.map((value) => {
        return (
          <span key={value}>
            <input className="hidden" type="radio" name="switch" checked={selected === value} readOnly />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control,jsx-a11y/no-noninteractive-element-interactions */}
            <label
              className="w-[1.25rem] h-[1rem] opacity-0 relative z-[3] text-[2px] float-left cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                handleChangeRating(value);
              }}
            />
          </span>
        );
      })}
      <span
        className="block absolute z-1 top-[-11.5%] left-0 w-[1.32rem] h-[1.32rem] bg-[#216BA5] rounded-full ease-out duration-300"
        style={selectionStyle()}
      />
    </div>
  );
};

export { ToggleSwitch };

import React from 'react';
import { useTranslation } from 'react-i18next';

interface TableHeaderProps {
  titles: { id: number; title: string }[];
  isHaveActionButton?: boolean;
}

const TableHeader = ({ titles, isHaveActionButton = false }: TableHeaderProps) => {
  const { t } = useTranslation();

  return (
    // length + 1 FOR ACTION BUTTON
    <div className={`grid grid-columns-${isHaveActionButton ? titles.length + 1 : titles.length}`}>
      {titles.map((item, index) => {
        return (
          <div
            key={item.id}
            className={`
            py-3.5 px-3 
            ${index === 0 ? 'sm:pl-6' : ''}
            ${index === (isHaveActionButton ? titles.length - 1 : titles.length) ? 'sm:pr-6' : ''} 
            text-left text-sm font-semibold text-gray-900
            `}
          >
            {t(item.title)}
          </div>
        );
      })}
    </div>
  );
};

export { TableHeader };

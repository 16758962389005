import React, { useMemo } from 'react';
import _ from 'lodash';
import classNames from 'classnames/bind';
import { BagData, ID } from '../../../providers/board/boardTypes';
import { EmptyBoardCard } from '../emptyBoardCard/EmptyBoardCard';
import { Order } from '../order/Order';
import { useBoardData } from '../../../providers/board/boardDataContext';
import { SortableBagOrder } from './SortableBagOrder';
import { OrdersBagStatus } from '../../../generated/graphql';
import { useBoardStateData } from '../../../providers/board/boardStateContext';
import { useDriversData } from '../../../providers/drivers/driversDataContext';
import { DriverData } from '../../../providers/drivers/driversTypes';
import { DriversDropdown } from './DriversDropdown';

interface Props {
  bag: BagData;
  drivers: DriverData[];
  isActive: boolean;
}

const emptyBagLength = 3;

function isDriverValidForBag(driver: DriverData, bag: BagData) {
  return !bag.activeOrdersBag || bag.activeOrdersBag.validDrivers.map(({ id }) => id).includes(driver.id);
}

const OrderBag: React.FC<Props> = ({ drivers, bag, isActive }) => {
  const { removeOrderFromBag, swapOrderPriority } = useBoardData();
  const { addDriverToBag } = useDriversData();
  const { selectedBagId, selectedOrderId, setSelectedOrderId } = useBoardStateData();

  async function handleMoveCard(a: ID, b: ID) {
    await swapOrderPriority(a, b);
  }

  async function handleDriverDropdownChange(driver: DriverData | null) {
    await addDriverToBag(bag.id, driver?.id || null);
  }

  const validDrivers = useMemo(() => {
    return drivers.filter((driver) => isDriverValidForBag(driver, bag));
  }, [drivers, bag]);

  const orders = useMemo(() => {
    return _.orderBy(bag.activeOrdersBag?.orders ?? [], (order) => order.orderinbag.priority);
  }, [bag]);

  return (
    <div className={classNames('h-full w-full', { isActive: isActive ? 'bg-red-400' : 'bg-red-400' })}>
      <div
        className={`${bag.activeOrdersBag?.status !== OrdersBagStatus.InProgress ? 'bg-sky-300' : 'bg-amber-400'} ${
          bag.id === selectedBagId ? 'bg-red-300' : ''
        }
         rounded-t-md px-2 py-2 text-left text-sm font-semibold text-gray-900 sm:px-2 flex-col justify-between`}
      >
        <span>{bag.code}</span>
        {bag.activeOrdersBag?.orders.length ? <span className="pl-2">({bag.activeOrdersBag.orders?.length})</span> : ''}
        <div>
          <DriversDropdown
            drivers={validDrivers}
            value={bag.activeOrdersBag?.driver}
            onChange={handleDriverDropdownChange}
          />
        </div>
      </div>
      <div className="h-[298px] mb-[10px] overflow-y-auto">
        <div className="p-2">
          <div className="pb-2">
            <EmptyBoardCard />
          </div>
          {emptyBagLength - (orders.length ?? 0) > 0
            ? new Array(emptyBagLength - 1 - (bag.activeOrdersBag?.orders.length ?? 0)).fill(1).map((o, index) => {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={index} className="pb-2">
                    <EmptyBoardCard />
                  </div>
                );
              })
            : null}

          {orders.map((order) => {
            return (
              <div key={order.orderNumber} className="pb-2">
                <SortableBagOrder id={order.id} index={order.orderinbag.priority} moveCard={handleMoveCard}>
                  <div onClick={() => setSelectedOrderId(order.id)}>
                    <Order order={order} isSelected={selectedOrderId === order.id} onRemoveOrder={removeOrderFromBag} />
                  </div>
                </SortableBagOrder>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export { OrderBag };

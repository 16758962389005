import React from 'react';
import { CashIcon, PlusSmIcon, ClockIcon, ExclamationCircleIcon, CheckCircleIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { OrderPaymentStatus } from '../../../generated/graphql';

type PaymentStatus = OrderPaymentStatus | 'CASH';

const getStylesByPaymentStatus = (status: PaymentStatus | null) => {
  switch (status) {
    case OrderPaymentStatus.WaitingPayment:
      return 'bg-amber-400 hover:bg-amber-200 text-black hover:text-black border-amber-400';
    case OrderPaymentStatus.Payed:
      return 'bg-green-400 hover:bg-green-200 text-black hover:text-black border-green-400';
    case OrderPaymentStatus.Failed:
      return 'bg-red-400 hover:bg-red-200 text-white hover:text-black border-red-400';
    case 'CASH':
      return 'bg-slate-400 hover:bg-slate-400 opacity-75 text-black hover:text-black border-slate-400';
    default:
      return 'bg-transparent hover:bg-amber-200 text-black hover:text-black border-amber-400 hover:border-transparent';
  }
};

const getIconByPaymentStatus = (status: PaymentStatus | null) => {
  switch (status) {
    case OrderPaymentStatus.WaitingPayment:
      return <ClockIcon />;
    case OrderPaymentStatus.Payed:
      return <CheckCircleIcon />;
    case OrderPaymentStatus.Failed:
      return <ExclamationCircleIcon />;
    case 'CASH':
      return <CashIcon />;
    default:
      return <PlusSmIcon />;
  }
};

const paymentStatusTranslation = (status: PaymentStatus | null) => {
  switch (status) {
    case OrderPaymentStatus.WaitingPayment:
      return 'Waiting payment';
    case OrderPaymentStatus.Payed:
      return 'Payed';
    case OrderPaymentStatus.Failed:
      return 'Payment failed';
    case 'CASH':
      return 'Cash';
    default:
      return 'Create link';
  }
};

interface IPaymentButton {
  handleClick: () => void;
  paymentStatus: PaymentStatus | null;
}

const OrdersTablePaymentButton = ({ handleClick, paymentStatus }: IPaymentButton) => {
  const { t } = useTranslation();
  return (
    <button
      disabled={paymentStatus === 'CASH'}
      onClick={(e) => {
        e.stopPropagation();
        handleClick();
      }}
      className={`whitespace-nowrap font-semibold p-[6px] min-h-[44px] lg:min-h-auto lg:py-2 lg:px-4 border focus:outline-none rounded-full lg:rounded
        ${getStylesByPaymentStatus(paymentStatus)}`}
    >
      <span className="hidden lg:inline">{t(paymentStatusTranslation(paymentStatus))}</span>
      <span className="lg:hidden block w-[30px]">{getIconByPaymentStatus(paymentStatus)}</span>
    </button>
  );
};

export { OrdersTablePaymentButton };

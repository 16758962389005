import React from 'react';
import { useNavigate } from 'react-router-dom';

interface IPaymentChangeItemRowProps {
  id: string;
  haveDetailView: boolean;
  itemValues: {
    id: number;
    type: 'string' | 'number';
    value: string | number;
  }[];
}

const PaymentChangeItemRow: React.FC<IPaymentChangeItemRowProps> = ({ id, itemValues, haveDetailView }) => {
  const navigate = useNavigate();

  return (
    <div
      key={id}
      onClick={() => (haveDetailView ? navigate(`/orders/${id}`) : false)}
      className={`grid grid-columns-${itemValues.length} hover:bg-yellow-100 cursor-pointer border-t border-gray-300 items-center`}
    >
      {itemValues.map((item, index) => {
        return (
          <div
            key={item.id}
            className={`whitespace-normal break-all px-3 py-4 text-sm
							${index === 0 ? 'font-medium text-gray-900 sm:pl-6' : 'text-gray-500'}
							${index === itemValues.length ? 'sm:pr-6' : ''}`}
          >
            {item.value}
          </div>
        );
      })}
    </div>
  );
};

export { PaymentChangeItemRow };

import React, { createContext, useContext, useMemo } from 'react';
import { useParams } from 'react-router';
import { FetchResult } from '@apollo/client';
import { useOrderDetailDataHook } from './orderDetailHooks';
import {
  OrderDocument,
  OrderQuery,
  UpdateOrderMutation,
  UpdateOrderType,
  useUpdateOrderMutation,
} from '../../generated/graphql';

const OrderDetailDataContext = createContext<{
  loading: boolean;
  updateOrder: (input: UpdateOrderType) => Promise<FetchResult<UpdateOrderMutation>>;
  order: OrderQuery | undefined;
}>(undefined!);

export const OrderDetailDataProvider = ({ children }: any) => {
  const params = useParams();

  const { loading: loadingData, order } = useOrderDetailDataHook(params.id!);
  const [updateOrderMutation, { loading: updateOrderLoading }] = useUpdateOrderMutation({
    refetchQueries: [OrderDocument],
  });

  const loading = useMemo(() => {
    return loadingData || updateOrderLoading;
  }, [loadingData, updateOrderLoading]);

  const value = useMemo(() => {
    async function updateOrder(input: UpdateOrderType) {
      return updateOrderMutation({
        variables: {
          input,
        },
      });
    }

    return {
      loading,
      order,
      updateOrder,
    };
  }, [loading, updateOrderMutation, order]);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <OrderDetailDataContext.Provider value={value}>{children}</OrderDetailDataContext.Provider>
  );
};

export const useOrderDetailData = () => useContext(OrderDetailDataContext);

import React, { useEffect, useMemo, useState } from 'react';
import { Placemark, YMaps, Map, Polyline, Circle } from 'react-yandex-maps';
import { OrderData } from '../../../providers/board/boardTypes';
import { formatOrderInterval } from '../../../utils/orders';
import { useBoardStateData } from '../../../providers/board/boardStateContext';

interface Props {
  orders: OrderData[];
}

interface Props1 {
  order: OrderData;
  highlighted?: boolean;
  onClick?: () => void;
}

const HEADQUARTERS = [43.315434, 45.68801];

function getCoordsFromOrder(order: OrderData) {
  return [order.address.location.coordinates[1], order.address.location.coordinates[0]];
}

function OrderPlacemark({ order, onClick, highlighted = false }: Props1) {
  return (
    <Placemark
      geometry={getCoordsFromOrder(order)}
      properties={{
        iconContent: `${order.orderinbag ? `${order.orderinbag.priority + 1} - ` : ''} ${formatOrderInterval(order)}`,
        balloonContentHeader: `${formatOrderInterval(order)}`,
        balloonContent: `${formatOrderInterval(order)}`,
      }}
      options={{
        // eslint-disable-next-line no-nested-ternary
        preset: highlighted
          ? 'islands#redStretchyIcon'
          : order.orderinbag
          ? 'islands#greyStretchyIcon'
          : 'islands#yellowStretchyIcon',
      }}
      onClick={onClick}
    />
  );
}

function Radii({ radii, center }: { radii: number[]; center: number[] }) {
  return (
    <>
      {radii.map((radius) => (
        <Circle
          key={radius}
          defaultGeometry={[center, radius]}
          options={{
            fillColor: 'rgba(131,227,104,0.02)',
            strokeColor: 'rgba(68,188,231,0.67)',
            strokeOpacity: 0.8,
            strokeWidth: 2.5,
          }}
        />
      ))}
    </>
  );
}

export function OrderMap({ orders }: Props) {
  const { selectedOrderId, selectedBagId, setSelectedOrderId } = useBoardStateData();

  const [mapInstance, setMapInstance] = useState<any>(null);

  function handleOrderClick(order: OrderData) {
    setSelectedOrderId(order.id);
  }

  const cleanOrders = useMemo(() => {
    return (
      [...orders]
        .filter((order) => order.address?.location?.coordinates[0])
        .filter((order) => !selectedBagId || order?.orderinbag?.ordersBag.bag.id === selectedBagId)
        // sorting to separate selected order to top
        // eslint-disable-next-line no-nested-ternary
        .sort((a, b) => (b.id === selectedOrderId ? -1 : a.id === selectedOrderId ? 1 : 0))
    );
  }, [orders, selectedOrderId, selectedBagId]);

  const polylinePoints = useMemo(() => {
    return [
      HEADQUARTERS,
      ...[...cleanOrders]
        .filter(({ orderinbag }) => orderinbag)
        .filter((order) => order?.orderinbag?.ordersBag.bag.id === selectedBagId)
        .sort((a, b) => a.orderinbag.priority - b.orderinbag.priority)
        .map(getCoordsFromOrder),
    ];
  }, [cleanOrders, selectedBagId]);

  useEffect(() => {
    const order = cleanOrders.find(({ id }) => id === selectedOrderId);
    if (order) {
      mapInstance?.panTo([order.address.location.coordinates[1], order.address.location.coordinates[0]]);
    }
  }, [mapInstance, cleanOrders, selectedOrderId]);

  return (
    <div className="mb-4 w-[100%] h-[29.5rem]">
      <YMaps query={{ mode: 'debug' }}>
        <Map instanceRef={setMapInstance} width="100%" height="100%" defaultState={{ center: HEADQUARTERS, zoom: 12 }}>
          {cleanOrders.map((order, index) => (
            <OrderPlacemark
              /* index is used deliberately to update the order of elements so that the selected order is shown on top */
              /* eslint-disable-next-line react/no-array-index-key */
              key={index}
              highlighted={selectedOrderId === order.id}
              order={order}
              onClick={() => handleOrderClick(order)}
            />
          ))}
          <Placemark
            geometry={HEADQUARTERS}
            properties={{
              iconContent: `Parhato`,
            }}
            options={{
              iconLayout: 'default#image',
              iconImageHref: 'assets/icon/logo.jpg',
              iconImageSize: [30, 30],
              iconImageOffset: [-15, -15],
            }}
          />
          <Radii radii={[3000, 5000]} center={HEADQUARTERS} />
          <Polyline
            geometry={polylinePoints}
            options={{
              balloonCloseButton: false,
              strokeColor: '#000',
              strokeWidth: 4,
              strokeOpacity: 0.5,
            }}
          />
        </Map>
      </YMaps>
    </div>
  );
}

import moment from 'moment';
import { format, parse } from 'date-fns';
import { OrderDeliveryStatus, OrderType, OrderTypeEdge } from '../generated/graphql';

export type GroupedOrdersTitle = {
  type: string;
  date: string;
  id: string;
};

export interface ISortedBoardItem {
  interval: string;
  ordersByHour: Array<OrderType>;
  intervalEnd: string;
}

export type GroupedOrdersSubTitle = {
  type: string;
  deliveryStartInterval: string;
  deliveryEndInterval: string;
  id: string;
};

// eslint-disable-next-line @typescript-eslint/no-shadow
const formatDate = (field: string, format: string) => {
  return moment(field).format(format);
};

function removeSecondsFromTimeString(timeString: string) {
  return format(parse(timeString, 'HH:mm:ss', new Date()), 'HH:mm');
}

export function formatOrderInterval({
  deliveryIntervalStart,
  deliveryIntervalEnd,
}: {
  deliveryIntervalStart: string;
  deliveryIntervalEnd: string;
}) {
  return `${removeSecondsFromTimeString(deliveryIntervalStart)}-${removeSecondsFromTimeString(deliveryIntervalEnd)}`;
}

// eslint-disable-next-line @typescript-eslint/no-shadow
const getOrdersKeys = (orders: { [key: string]: any }, format: string) => {
  return Object.keys(orders).sort((x, y) => moment(y, format).unix() - moment(x, format).unix());
};

export const groupOrders = (orders: Array<OrderTypeEdge>, groupBy?: string) => {
  return orders?.reduce((acc: { [key: string]: any }, el: OrderTypeEdge) => {
    let orderDate;

    switch (groupBy) {
      case 'hour': {
        orderDate = formatDate(`${el.node.deliveryDate} ${el.node.deliveryIntervalStart}`, 'YYYY-MM-DD HH:mm');
        break;
      }
      case 'day': {
        orderDate = formatDate(`${el.node.deliveryDate}`, 'YYYY-MM-DD');
        break;
      }
      default: {
        orderDate = formatDate(`${el.node.deliveryDate}`, 'DD-MM-YYYY');
      }
    }

    if (acc[orderDate]) {
      return { ...acc, [orderDate]: acc[orderDate].concat([el]) };
    }
    return { ...acc, [orderDate]: [el] };
  }, {});
};

export const generateOrdersItems = (orders: Array<OrderTypeEdge>) => {
  if (!orders || !orders.length) {
    return [];
  }
  const ordersByDay = groupOrders(orders, 'day');
  let ordersByHour: { [key: string]: any } = {};
  /*eslint-disable */
  Object.keys(ordersByDay).map((key) => {
    const orders = groupOrders(ordersByDay[key], 'hour');
    const ordersKeys = getOrdersKeys(orders, 'YYYY-MM-DD HH:mm');
    const sortedOrders = ordersKeys.reduce((acc: { [key: string]: any }[], date) => {
      const sortedItems = orders[date].sort((x: OrderTypeEdge, y: OrderTypeEdge) => {
        return (
          new Date(`${y.node.deliveryDate} ${y.node.deliveryIntervalStart}`).getDate() -
          new Date(`${x.node.deliveryDate} ${x.node.deliveryIntervalStart}`).getDate()
        );
      });
      const deliveryEndInterval = `${orders[date][0].node.deliveryDate} ${orders[date][0].node.deliveryIntervalEnd}`;
      return acc.concat([
        {
          type: 'hour',
          deliveryStartInterval: moment(date).format('HH:mm'),
          deliveryEndInterval: moment(deliveryEndInterval).format('HH:mm'),
          id: date,
        },
        ...sortedItems,
      ]);
    }, []);
    ordersByHour = {
      ...ordersByHour,
      [key]: sortedOrders,
    };
  });
  /*eslint-disable */

  return getOrdersKeys(ordersByHour, 'YYYY-MM-DD').map((date) => {
    const sortedItems = ordersByHour[date].sort(
      (x: OrderTypeEdge & GroupedOrdersTitle, y: OrderTypeEdge & GroupedOrdersTitle) => {
        if (y.type === 'hour' || x.type === 'hour') {
          return true;
        }
        return (
          new Date(`${y.node.deliveryDate} ${y.node.deliveryIntervalStart}`).getDate() -
          new Date(`${x.node.deliveryDate} ${x.node.deliveryIntervalStart}`).getDate()
        );
      }
    );
    return { type: 'day', date: formatDate(date, 'DD-MM-YYYY'), id: date, items: sortedItems };
  }, []);
};

const groupBoardItems = (orders: Array<OrderType>, groupBy?: string) => {
  return orders?.reduce((acc: { [key: string]: any }, el: OrderType) => {
    let orderDate;

    switch (groupBy) {
      case 'hour': {
        orderDate = formatDate(`${el.deliveryDate} ${el.deliveryIntervalStart}`, 'YYYY-MM-DD HH:mm');
        break;
      }
      case 'day': {
        orderDate = formatDate(`${el.deliveryDate}`, 'YYYY-MM-DD');
        break;
      }
      default: {
        orderDate = formatDate(`${el.deliveryDate}`, 'YYYY-MM-DD');
      }
    }

    if (acc[orderDate]) {
      return { ...acc, [orderDate]: acc[orderDate].concat([el]) };
    }
    return { ...acc, [orderDate]: [el] };
  }, {});
};

export const generateBoardCards = (orders: Array<OrderType>) => {
  if (!orders || !orders.length) {
    return [];
  }
  const ordersByHour = groupBoardItems(orders, 'hour');

  return Object.entries(ordersByHour)
    .map<ISortedBoardItem>(([interval, ordersByHour]) => ({
      interval,
      ordersByHour,
      intervalEnd: ordersByHour[0].deliveryIntervalEnd,
    }))
    .sort((a, b) => Number(moment(a.interval).format('HH')) - Number(moment(b.interval).format('HH')));
};

export const getOrderStatus = (status: string) => {
  switch (status) {
    case OrderDeliveryStatus.A_1: {
      return 'Pending';
    }
    case OrderDeliveryStatus.A_2: {
      return 'Processing';
    }
    case OrderDeliveryStatus.A_3: {
      return 'Completed';
    }
    case OrderDeliveryStatus.A_5: {
      return 'Waiting for change';
    }
    default: {
      return 'Canceled';
    }
  }
};

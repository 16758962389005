import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  OrderDeliveryStatus,
  OrderPaymentStatus,
  OrdersDocument,
  OrderTypeEdge,
  PickerTypeEdge,
  useAppointPickerForOrderMutation,
  useGetSberPaymentsFormLinkMutation,
  useOrdersQuery,
  usePickersQuery,
} from '../../generated/graphql';
import { generateOrdersItems } from '../../utils/orders';
import { Spinner } from '../../components/UI';
import { OrdersTable } from '../../components/ordersTable/OrdersTable';
import { Pagination } from '../../components/pagination/Pagination';
import { PickerDataProvider } from '../../providers/orders/pickerDataContext';
import { successToast } from '../../utils/utils';

export type PaymentModalData = {
  orderNumber: number;
  customerPhone: string;
  amount: number;
  paymentLink: string;
  status: OrderPaymentStatus;
};

const countToDisplay = 50;

const Orders = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [paymentModalData, setPaymentModalData] = useState<PaymentModalData>({
    orderNumber: 0,
    amount: 0,
    customerPhone: '',
    paymentLink: '',
    status: OrderPaymentStatus.WaitingPayment,
  });

  const {
    data: ordersData,
    loading: ordersLoading,
    refetch,
  } = useOrdersQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      first: countToDisplay,
      offset: countToDisplay * (page - 1),
    },
  });

  const { data: pickersData, loading: pickersLoading } = usePickersQuery();

  const [appointPickerMutation, { loading: appointPickerMutationLoading }] = useAppointPickerForOrderMutation({
    notifyOnNetworkStatusChange: true,
    refetchQueries: [{ query: OrdersDocument, variables: { status: [OrderDeliveryStatus.A_1] } }],
  });

  const refetchOrders = useCallback(
    (pageNumber: number) => {
      setPage(pageNumber);
      refetch({
        status: [OrderDeliveryStatus.A_1],
        first: countToDisplay,
        offset: countToDisplay * (pageNumber - 1),
      });
    },
    [refetch, setPage]
  );

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const totalCount = ordersData?.orders?.totalCount;

  const [orders, setOrders] = useState<{ type: string; date: string; id: string; items: any }[]>([]);

  const [getPaymentLink, { loading: paymentLinkLoading }] = useGetSberPaymentsFormLinkMutation({
    notifyOnNetworkStatusChange: true,
    refetchQueries: [{ query: OrdersDocument, variables: { status: [OrderDeliveryStatus.A_1] } }],
    onCompleted: (res) => {
      successToast(t('Link successfully generated!'));
      setPaymentModalData({
        customerPhone: res.getSberPaymentsFormLink?.payment?.order?.customerPhone,
        orderNumber: res.getSberPaymentsFormLink?.payment?.order?.orderNumber,
        amount:
          Number(res.getSberPaymentsFormLink?.payment?.amount) ||
          res.getSberPaymentsFormLink?.payment?.order?.totalCost,
        paymentLink: res.getSberPaymentsFormLink?.payment?.formUrl,
        status: res.getSberPaymentsFormLink?.payment?.status,
      });
    },
  });

  useEffect(() => {
    setOrders(generateOrdersItems(ordersData?.orders.edges as Array<OrderTypeEdge>));
  }, [ordersData]);

  return (
    <>
      {ordersLoading || pickersLoading || appointPickerMutationLoading || paymentLinkLoading ? (
        <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-[100] overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center">
          <Spinner width="w-14" height="h-14" />
        </div>
      ) : null}
      <PickerDataProvider>
        <div className="container mx-auto">
          <h2 className="text-5xl font-normal leading-normal mt-0 mb-2 text-black">{t('Orders')}</h2>
          <Pagination
            toPage={refetchOrders}
            activePage={page}
            rangeStart={(countToDisplay * (page - 1) === 0 ? 1 : countToDisplay * (page - 1) + 1)!}
            rangeEnd={countToDisplay * page > totalCount! ? totalCount! : countToDisplay * page}
            totalCount={totalCount!}
            lastPage={Math.ceil(totalCount! / countToDisplay)}
          />
          <OrdersTable
            setPaymentModalData={setPaymentModalData}
            paymentModalData={paymentModalData}
            getPaymentLink={(variables) => {
              getPaymentLink({
                variables,
              });
            }}
            appointPicker={(pickerId, orderNumber) => {
              appointPickerMutation({
                variables: {
                  pickerId,
                  orderNumber,
                },
              });
            }}
            pickers={pickersData?.pickers.edges as PickerTypeEdge[]}
            orders={orders}
          />
          <Pagination
            toPage={refetchOrders}
            activePage={page}
            rangeStart={countToDisplay * (page - 1) === 0 ? 1 : countToDisplay * (page - 1) + 1}
            rangeEnd={countToDisplay * page > totalCount! ? totalCount! : countToDisplay * page}
            totalCount={totalCount!}
            lastPage={Math.ceil(totalCount! / countToDisplay)}
          />
        </div>
      </PickerDataProvider>
    </>
  );
};

export { Orders };

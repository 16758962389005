import React, { useState } from 'react';
import { PaymentModalData } from '../../pages/orders/Orders';
import { GetSberPaymentsFormLinkMutationVariables, OrderTypeEdge, PickerTypeEdge } from '../../generated/graphql';
import { OrdersTableDateHeader } from './ordersTableDateHeader/OrdersTableDateHeader';
import { GroupedOrdersSubTitle } from '../../utils/orders';
import { PaymentLinkModal } from '../paymentLinkModal/PaymentLinkModal';
import { OrdersTableHead } from './ordersTableHead/OrdersTableHead';
import { OrdersTableHourRow } from './ordersTableHourRow/OrdersTableHourRow';
import { OrdersTableItemRow } from './ordersTableItemRow/OrdersTableItemRow';

interface IOrdersTable {
  orders: { [key: string]: any };
  setPaymentModalData: (data: PaymentModalData) => void;
  paymentModalData: PaymentModalData;
  getPaymentLink: (variables: GetSberPaymentsFormLinkMutationVariables) => void;
  appointPicker: (pickerId: string | null, orderNumber: string) => void;
  pickers: PickerTypeEdge[] | undefined;
}

const OrdersTable = ({
  orders,
  appointPicker,
  pickers,
  setPaymentModalData,
  paymentModalData,
  getPaymentLink,
}: IOrdersTable) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div>
      {orders.map((order: { [key: string]: any }) => {
        return (
          <div key={order.date} className="mx-4 sm:m-0">
            <OrdersTableDateHeader date={order.date} />
            <div className="-mx-4 mt-8 shadow overflow-x-scroll ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
              <table className="divide-y divide-gray-300 w-full">
                <OrdersTableHead />
                <tbody className="divide-y divide-gray-200 bg-white">
                  {order.items.map((item: OrderTypeEdge & GroupedOrdersSubTitle) => {
                    return item.type && item.type === 'hour' ? (
                      <OrdersTableHourRow
                        key={item.id}
                        deliveryEndInterval={item.deliveryEndInterval}
                        deliveryStartInterval={item.deliveryStartInterval}
                      />
                    ) : (
                      <OrdersTableItemRow
                        key={item.node.orderNumber}
                        item={item.node}
                        pickers={pickers}
                        appointPicker={appointPicker}
                        openPaymentModal={() => {
                          setPaymentModalData({
                            customerPhone: item.node.customerPhone,
                            orderNumber: item.node.orderNumber,
                            amount: Number(item.node.orderpaymentSet?.edges[0]?.node?.amount) || item.node?.totalCost,
                            paymentLink: item.node.orderpaymentSet?.edges[0]?.node?.formUrl || '',
                            status: item.node.orderpaymentSet?.edges[0]?.node?.status,
                          });
                          setIsModalOpen(true);
                        }}
                      />
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        );
      })}
      <PaymentLinkModal
        customerPhone={paymentModalData?.customerPhone}
        paymentLink={paymentModalData?.paymentLink}
        orderNumber={paymentModalData?.orderNumber}
        amount={paymentModalData?.amount}
        status={paymentModalData.status}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        getPaymentLink={(variables: GetSberPaymentsFormLinkMutationVariables) => getPaymentLink(variables)}
      />
    </div>
  );
};

export { OrdersTable };

import React from 'react';
import { Board } from '../../components/board/Board';
import { BoardDataProvider } from '../../providers/board/boardDataContext';
import { BoardStateProvider } from '../../providers/board/boardStateContext';
import { DriversDataProvider } from '../../providers/drivers/driversDataContext';

const BoardPage = () => {
  return (
    <DriversDataProvider>
      <BoardDataProvider>
        <BoardStateProvider>
          <Board />
        </BoardStateProvider>
      </BoardDataProvider>
    </DriversDataProvider>
  );
};

export { BoardPage };

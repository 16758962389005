import React, { ReactElement, useEffect, useRef } from 'react';
import classNames from 'classnames/bind';
import { useDrag } from 'react-dnd';
import { BagData, ID } from '../../../providers/board/boardTypes';

// TODO:: inherit/extend BoardCard props
interface Props {
  isSelected: boolean;
  orderNumber: string;
  onDrop?: (bagId: ID) => void;
  children: ReactElement;
}

interface DropResult {
  bag: BagData;
}

const DraggableOrder = ({ isSelected, orderNumber, onDrop, children }: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isSelected && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [isSelected]);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'order',
    item: { orderNumber },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult<DropResult>();
      if (item && dropResult) {
        onDrop?.(dropResult.bag.id);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  const opacity = isDragging ? 0.3 : 1;
  return (
    <div
      ref={drag}
      data-testid="box"
      style={{ opacity }}
      className={classNames('mx-2 my-2 rounded max-w-[280px] w-[196px]')}
    >
      <div ref={ref} />
      <div>{children}</div>
    </div>
  );
};

export { DraggableOrder };

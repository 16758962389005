import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getOrderStatus } from '../../utils/orders';
import { OrderDeliveryStatus, UpdateOrderType } from '../../generated/graphql';
import { useOrderDetailData } from '../../providers/orderDetail/orderDetailDataContext';
import { Spinner } from '../UI';

const OrderDetail = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { order, loading: orderLoading, updateOrder } = useOrderDetailData();

  const { register, handleSubmit, reset } = useForm<UpdateOrderType>({
    mode: 'onBlur',
    shouldUnregister: true, // this is a necessary flag because order.order has excess fields
  });

  useEffect(() => {
    reset({ ...order?.order });
  }, [reset, order]);

  const handleSave = useCallback(
    (values: UpdateOrderType) => {
      updateOrder({ ...values, id: order!.order.id! });
      navigate(-1);
    },
    [order, updateOrder, navigate]
  );

  return (
    <>
      <div className="bg-white py-6 px-4 overflow-hidden sm:px-4 lg:px-4">
        <div className="relative max-w-xl mx-auto">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:tracking-tight">
            {t('Change order')}
          </h2>
          <div className="mt-6">
            {order?.order && (
              <form onSubmit={handleSubmit(handleSave)} className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                <div className="sm:col-span-2">
                  <label htmlFor="orderNumber" className="block text-sm font-medium text-gray-700">
                    {t('Order ID')}
                  </label>
                  <div className="mt-1">
                    <input
                      value={order.order.orderNumber}
                      type="number"
                      name="orderNumber"
                      readOnly
                      disabled
                      placeholder={t('Order ID')}
                      id="orderNumber"
                      className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md disabled:bg-gray-100"
                    />
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label htmlFor="shippingCost" className="block text-sm font-medium text-gray-700">
                    {t('Shipping cost')}
                  </label>
                  <div className="mt-1">
                    <input
                      id="shippingCost"
                      placeholder={t('Shipping cost')}
                      type="number"
                      className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                      {...register('shippingCost')}
                    />
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label htmlFor="totalCost" className="block text-sm font-medium text-gray-700">
                    {t('Total cost')}
                  </label>
                  <div className="mt-1">
                    <input
                      id="totalCost"
                      placeholder={t('Total cost')}
                      type="number"
                      step={0.01}
                      max={100_000}
                      min={0}
                      className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                      {...register('totalCost')}
                    />
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label htmlFor="customerFullname" className="block text-sm font-medium text-gray-700">
                    {t("Buyer's full name")}
                  </label>
                  <div className="mt-1">
                    <input
                      id="customerFullname"
                      placeholder={t("Buyer's full name")}
                      type="text"
                      className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                      {...register('customerFullname')}
                    />
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label htmlFor="customerPhone" className="block text-sm font-medium text-gray-700">
                    {t('Buyer phone')}
                  </label>
                  <div className="mt-1">
                    <input
                      id="customerPhone"
                      placeholder={t('Buyer phone')}
                      type="tel"
                      className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                      {...register('customerPhone')}
                    />
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                    {t('Address')}
                  </label>
                  <div className="mt-1">
                    <input
                      readOnly
                      value={order?.order.address.fullAddress}
                      id="address"
                      placeholder={t('Address')}
                      type="text"
                      className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <div>
                    <label htmlFor="paymentMethod" className="block text-sm font-medium text-gray-700">
                      {t('Payment method')}
                    </label>
                    <select
                      id="paymentMethod"
                      className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                      {...register('paymentMethod.id')}
                    >
                      {order?.paymentMethods.edges.map((method) => {
                        return (
                          <option key={method.node.id} value={method.node.id}>
                            {method.node.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <div>
                    <label htmlFor="shippingMethod" className="block text-sm font-medium text-gray-700">
                      {t('Shipping method')}
                    </label>
                    <select
                      id="shippingMethod"
                      className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                      {...register('shippingMethod.id')}
                    >
                      {order?.shippingMethods.edges.map((method) => {
                        return (
                          <option key={method.node.id} value={method.node.id}>
                            {method.node.title}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label htmlFor="customerNote" className="block text-sm font-medium text-gray-700">
                    {t('Note')}
                  </label>
                  <div className="mt-1">
                    <textarea
                      readOnly
                      id="customerNote"
                      name="customerNote"
                      rows={4}
                      className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md"
                      value={order?.order.customerNote || ''}
                    />
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <div>
                    <label htmlFor="deliveryStatus" className="block text-sm font-medium text-gray-700">
                      {t('Delivery status')}
                    </label>
                    <select
                      id="deliveryStatus"
                      className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                      {...register('deliveryStatus')}
                    >
                      {(Object.values(OrderDeliveryStatus) as (keyof typeof OrderDeliveryStatus)[]).map((key) => {
                        return (
                          <option value={key} key={key}>
                            {t(getOrderStatus(key))}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label htmlFor="deliveryDate" className="block text-sm font-medium text-gray-700">
                    {t('Delivery date')}
                  </label>
                  <div className="mt-1">
                    <input
                      id="deliveryDate"
                      placeholder={t('Delivery date')}
                      type="date"
                      className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                      {...register('deliveryDate')}
                    />
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label htmlFor="deliveryIntervalStart" className="block text-sm font-medium text-gray-700">
                    {t('Start of delivery interval')}
                  </label>
                  <div className="mt-1">
                    <input
                      id="deliveryIntervalStart"
                      placeholder={t('Start of delivery interval')}
                      type="time"
                      className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                      {...register('deliveryIntervalStart')}
                    />
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label htmlFor="deliveryIntervalEnd" className="block text-sm font-medium text-gray-700">
                    {t('End of delivery interval')}
                  </label>
                  <div className="mt-1">
                    <input
                      id="deliveryIntervalEnd"
                      placeholder={t('Start of delivery interval')}
                      type="time"
                      className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                      {...register('deliveryIntervalEnd')}
                    />
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <div>
                    <label htmlFor="driver" className="block text-sm font-medium text-gray-700">
                      {t('Driver')}
                    </label>
                    <input
                      disabled
                      value={order.order.orderinbag?.ordersBag?.driver?.user.lastName}
                      id="driver"
                      name="driver"
                      className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                    />
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label htmlFor="createdAt" className="block text-sm font-medium text-gray-700">
                    {t('Date of creation')}
                  </label>
                  <div className="mt-1">
                    <input
                      value={moment(order?.order.createdAt).format('YYYY-MM-DD HH:mm')}
                      readOnly
                      id="createdAt"
                      name="createdAt"
                      placeholder={t('Time of creation')}
                      type="datetime"
                      className="py-3 px-4 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                <button
                  type="submit"
                  className="inline items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-amber-500 hover:bg-amber-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                >
                  {t('Save')}
                </button>
                <button
                  onClick={() => navigate(-1)}
                  type="button"
                  className="inline items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-amber-700 bg-amber-100 hover:bg-amber-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                >
                  {t('Cancel')}
                </button>
              </form>
            )}
          </div>
        </div>
      </div>

      {orderLoading && (
        <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center">
          <Spinner width="w-14" height="h-14" />
        </div>
      )}
    </>
  );
};

export { OrderDetail as OrderDetailComponent };

import React, { createContext, useContext, useMemo } from 'react';
import { useDriversDataHook } from './driversHooks';
import { DriverData } from './driversTypes';
import { DriverStatus, useAddDriverToBagMutation } from '../../generated/graphql';
import { ID } from '../board/boardTypes';

const DriversDataContext = createContext<{
  loading: boolean;
  drivers: DriverData[];
  activeDrivers: DriverData[];
  addDriverToBag: (bagId: ID, driverId: ID | null) => void;
}>(undefined!);

export const DriversDataProvider = ({ children }: any) => {
  const { drivers, loading: driversLoading } = useDriversDataHook();
  const [addDriverToBagMutation, { loading: loadingAddDriverMutation }] = useAddDriverToBagMutation();

  const activeDrivers = useMemo(() => drivers?.filter(({ status }) => status === DriverStatus.Active) ?? [], [drivers]);

  const loading = useMemo(() => {
    return driversLoading || loadingAddDriverMutation;
  }, [driversLoading, loadingAddDriverMutation]);

  const value = useMemo(() => {
    async function addDriverToBag(bagId: ID, driverId: ID | null) {
      await addDriverToBagMutation({
        variables: {
          bagId,
          driverId,
        },
      });
    }

    return {
      loading,
      drivers: drivers ?? [],
      activeDrivers,
      addDriverToBag,
    };
  }, [loading, drivers, activeDrivers, addDriverToBagMutation]);

  return <DriversDataContext.Provider value={value}>{children}</DriversDataContext.Provider>;
};

export const useDriversData = () => useContext(DriversDataContext);

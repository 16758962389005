import React from 'react';
import { OrderDetailComponent } from '../../components/orderDetail/OrderDetail';
import { DriversDataProvider } from '../../providers/drivers/driversDataContext';
import { OrderDetailDataProvider } from '../../providers/orderDetail/orderDetailDataContext';

const OrderDetail = () => {
  return (
    <OrderDetailDataProvider>
      <DriversDataProvider>
        <OrderDetailComponent />
      </DriversDataProvider>
    </OrderDetailDataProvider>
  );
};

export { OrderDetail };

import { useOrderQuery } from '../../generated/graphql';

export function useOrderDetailDataHook(orderId: string) {
  const { data: order, loading: orderLoading } = useOrderQuery({
    variables: {
      id: orderId,
    },
  });

  return {
    loading: orderLoading,
    order,
  };
}

import React, { ReactNode } from 'react';
import classNames from 'classnames/bind';
import { Popover } from '@headlessui/react';

export interface IBoardCard {
  id: number | string;
  index: number | string;
  isInBag?: boolean;
  isSelected?: boolean;
  active?: boolean;
  dimmed?: boolean;
  customerFullName: string;
  time: string;
  onRemoveOrder?: () => void;
  popupContent?: ReactNode;
}

const BoardCard: React.FC<IBoardCard> = ({
  id,
  index,
  isSelected = false,
  isInBag = false,
  active = false,
  dimmed = false,
  customerFullName,
  popupContent,
  onRemoveOrder,
  time,
}) => {
  return (
    <li className={classNames('col-span-1 flex rounded-md flex-col ', isSelected ? 'shadow-lg' : 'shadow-sm')}>
      <div
        className={classNames(
          'relative flex-shrink-0 flex items-center justify-center p-1 text-gray-900 text-sm font-medium rounded-t-md',
          // eslint-disable-next-line no-nested-ternary
          isSelected ? 'bg-red-400' : active ? 'bg-green-200' : dimmed ? 'bg-gray-200' : 'bg-amber-200 '
        )}
      >
        <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">{index}</div>
        <Popover className="">
          <Popover.Button>{id}</Popover.Button>
          <Popover.Panel className="absolute right-0 rounded-md shadow-md m-2 z-10">{popupContent}</Popover.Panel>
        </Popover>
      </div>
      <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-b-md truncate">
        <div className="flex-1 px-4 py-2 text-sm truncate">
          <span className="text-gray-900 font-medium hover:text-gray-600">{time}</span>
          <p className="text-gray-500">{customerFullName}</p>
        </div>
        <div className="flex-shrink-0 pr-2">
          {isInBag && (
            <button
              onClick={() => {
                onRemoveOrder?.();
              }}
              type="button"
              className="w-8 h-8 bg-white inline-flex items-center justify-center text-gray-400 rounded-full bg-transparent hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </button>
          )}
        </div>
      </div>
    </li>
  );
};

export { BoardCard };

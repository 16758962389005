import React from 'react';
import personImage from '../../../assets/images/person.png';

export interface Props {
  url?: string;
  className?: string;
  alt?: string;
}

const Image = ({ url, className, alt }: Props) => {
  return <img className={className} src={url} alt={alt} />;
};

Image.defaultProps = {
  url: personImage,
  alt: '',
  className: '',
};

export { Image };

import React from 'react';
import { ClockIcon, TruckIcon, ExclamationCircleIcon, CashIcon, CheckCircleIcon } from '@heroicons/react/outline';
import { useTranslation } from 'react-i18next';
import { getOrderStatus } from '../../../utils/orders';
import { OrderDeliveryStatus } from '../../../generated/graphql';

const getIconByDeliveryStatus = (status: OrderDeliveryStatus): React.ReactNode => {
  switch (status) {
    case OrderDeliveryStatus.A_1:
      return <ClockIcon />;
    case OrderDeliveryStatus.A_2:
      return <TruckIcon />;
    case OrderDeliveryStatus.A_3:
      return <CheckCircleIcon />;
    case OrderDeliveryStatus.A_4:
      return <ExclamationCircleIcon />;
    case OrderDeliveryStatus.A_5:
      return <CashIcon />;
    default:
      return <CashIcon />;
  }
};

const OrdersTableDeliveryStatus = ({ deliveryStatus }: { deliveryStatus: OrderDeliveryStatus }) => {
  const { t } = useTranslation();

  return (
    <div
      className={`whitespace-normal text-sm uppercase text-black  p-[6px] xl:px-2.5 xl:py-0.5 inline-block rounded-full xl:rounded ${`orderStatus--${deliveryStatus}`}`}
    >
      <span className="whitespace-nowrap font-semibold hidden xl:inline">
        {t(`${getOrderStatus(String(deliveryStatus))}`)}
      </span>
      <span className="xl:hidden block w-[30px]">{getIconByDeliveryStatus(deliveryStatus)}</span>
    </div>
  );
};

export { OrdersTableDeliveryStatus };

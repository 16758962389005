import React, { ReactElement, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { ID } from '../../../providers/board/boardTypes';

enum ItemTypes {
  CARD = 'card',
}

interface Props {
  index: number;
  id: ID;
  moveCard: (dragIndex: ID, hoverIndex: ID) => void;
  children: ReactElement;
}

const SortableBagOrder: React.FC<Props> = ({ id, index, moveCard, children }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop: () => ({ id }),
    // hover(item: DragItem, monitor) {
    //   if (!ref.current) {
    //     return;
    //   }
    //   const dragIndex = item.index;
    //   const hoverIndex = index;
    //
    //   // Don't replace items with themselves
    //   if (dragIndex === hoverIndex) {
    //     return;
    //   }
    //
    //   // Determine rectangle on screen
    //   const hoverBoundingRect = ref.current?.getBoundingClientRect();
    //
    //   // Get vertical middle
    //   const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
    //
    //   // Determine mouse position
    //   const clientOffset = monitor.getClientOffset();
    //
    //   // Get pixels to the top
    //   const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
    //
    //   // Only perform the move when the mouse has crossed half of the items height
    //   // When dragging downwards, only move when the cursor is below 50%
    //   // When dragging upwards, only move when the cursor is above 50%
    //
    //   // Dragging downwards
    //   if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
    //     return;
    //   }
    //
    //   // Dragging upwards
    //   if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
    //     return;
    //   }
    //
    //   // Note: we're mutating the monitor item here!
    //   // Generally it's better to avoid mutations,
    //   // but it's good here for the sake of performance
    //   // to avoid expensive index searches.
    //   item.index = hoverIndex;
    // },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end(item, monitor) {
      const dropResult = monitor.getDropResult<{ id: ID }>();
      if (item && dropResult && item.id !== dropResult.id) {
        moveCard(item.id, dropResult.id);
      }
    },
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <div ref={ref} style={{ opacity }} data-handler-id={handlerId}>
      {children}
    </div>
  );
};

export { SortableBagOrder };

import { createContext, useContext, useMemo } from 'react';
import { OrderDeliveryStatus, OrdersDocument, useEvaluatePickerMutation } from '../../generated/graphql';
import { ID } from '../board/boardTypes';

const PickerDataContext = createContext<{
  changeAssembly: (orderId: ID, rating: number) => void;
}>(undefined!);

export const PickerDataProvider = ({ children }: any) => {
  const [evaluatePickerMutation] = useEvaluatePickerMutation({
    refetchQueries: [{ query: OrdersDocument, variables: { status: [OrderDeliveryStatus.A_1] } }],
  });

  const value = useMemo(() => {
    async function changeAssembly(orderId: ID, rating: number) {
      await evaluatePickerMutation({
        variables: {
          orderId,
          rating,
        },
      });
    }

    return {
      changeAssembly,
    };
  }, [evaluatePickerMutation]);

  return <PickerDataContext.Provider value={value}>{children}</PickerDataContext.Provider>;
};

export const usePickerData = () => useContext(PickerDataContext);

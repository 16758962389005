import React from 'react';
import { ClipboardListIcon, CurrencyDollarIcon, ViewBoardsIcon } from '@heroicons/react/solid';

/* Styles */
import '@fortawesome/fontawesome-free/css/all.min.css';
import './styles/tailwind.css';

import './styles/index.css';

/* Router stuff */
// import Router from "./routes/Router";
/* Translation */
import './i18n/i18n';

import { Route, Routes, Navigate } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import { useAuth } from './contexts/auth-context';
import { SigninPage } from './pages/signin/SigninPage';
import { SidebarLayout } from './layout/SidebarLayout';
import { Orders } from './pages/orders/Orders';
import { OrderDetail } from './pages/orderDetail/OrderDetail';
import { BoardPage } from './pages/board/Board';
import { PaymentChanges } from './pages/paymentChanges/PaymentChanges';
import { PaymentChangesHistory } from './pages/paymentChangesHistory/PaymentChangesHistory';

const navigation = [
  { name: 'Board', href: '/board', icon: ViewBoardsIcon },
  { name: 'Orders', href: '/orders', icon: ClipboardListIcon },
  { name: 'Payment changes', href: '/changes', icon: CurrencyDollarIcon },
  { name: 'Payment changes history', href: '/changes-history', icon: CurrencyDollarIcon },
];
const userNavigation = [
  { name: 'Board', href: 'board' },
  { name: 'Orders', href: 'orders' },
  { name: 'Payment changes', href: 'changes' },
];

const App = () => {
  const authContext = useAuth();

  if (!authContext.user && !localStorage.getItem('user')) {
    return <SigninPage />;
  }

  return (
    <>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<SidebarLayout userNavigation={userNavigation} navigation={navigation} />}>
          <Route index element={<Navigate to="board" />} />
          <Route path="board" element={<BoardPage />} />
          <Route path="orders" element={<Orders />} />
          <Route path="orders/:id" element={<OrderDetail />} />
          <Route path="changes" element={<PaymentChanges />} />
          <Route path="changes-history" element={<PaymentChangesHistory />} />
        </Route>
      </Routes>
    </>
  );
};

export { App };

import cx from 'classnames/bind';

interface INoOrdersProps {
  title: string;
  stylesImg?: string;
}

function NoOrders({ title, stylesImg }: INoOrdersProps) {
  return (
    <>
      <img
        className={cx(stylesImg, 'block grayscale rounded-full w-[3.125rem] h-[3.125rem] opacity-[.3] mb-2')}
        src="assets/icon/logo.jpg"
        alt="logo"
      />
      <div className="font-medium text-sm opacity-[.5]">{title}</div>
    </>
  );
}

export { NoOrders };

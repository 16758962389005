import { useMemo } from 'react';
import { useBoardOrdersBagsQuery } from '../../generated/graphql';

export function useBoardOrdersBagsDriversData(pollInterval: number) {
  const { data, loading } = useBoardOrdersBagsQuery({ pollInterval });

  const orders = data?.orders?.edges.map(({ node }) => node);
  const bags = data?.bags?.edges.map(({ node }) => node);

  const ordersWithoutBags = useMemo(() => orders?.filter((order) => !order?.orderinbag), [orders]);

  return {
    loading,
    orders,
    bags,
    ordersWithoutBags,
  };
}

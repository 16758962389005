import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/solid';
import { DraggableOrder } from './draggable-order/DraggableOrder';
import { DroppableOrderBag } from './droppable-order-bag/DroppableOrderBag';
import { useBoardData } from '../../providers/board/boardDataContext';
import { Modal, Spinner } from '../UI';
import { ID, OrderData } from '../../providers/board/boardTypes';
import { generateBoardCards, ISortedBoardItem } from '../../utils/orders';
import { OrderType } from '../../generated/graphql';
import { OrderMap } from './map/OrderMap';
import { useBoardStateData } from '../../providers/board/boardStateContext';
import { NoOrders } from './noOrders/noOrders';
import { useDriversData } from '../../providers/drivers/driversDataContext';
import { Order } from './order/Order';

export function Board() {
  const { bags, orders, loading: boardDataLoading, addOrderToBag, ordersWithoutBags } = useBoardData();
  const { activeDrivers: drivers, loading: driversDataLoading } = useDriversData();
  const { selectedOrderId, setSelectedOrderId } = useBoardStateData();

  const [showIntervalMismatchModal, setShowIntervalMismatchModal] = useState(false);

  const { t } = useTranslation();

  const sortedBoardItems: ISortedBoardItem[] = useMemo(
    () => generateBoardCards(ordersWithoutBags as Array<OrderType>),
    [ordersWithoutBags]
  );

  async function handleDropOrderInBag(orderId: ID, bagId: ID) {
    await addOrderToBag(orderId, bagId);
  }

  return (
    <>
      <Modal
        show={showIntervalMismatchModal}
        messageHeader={t('Orders have different intervals')}
        onSubmit={() => {
          setShowIntervalMismatchModal(false);
        }}
      />
      <DndProvider backend={HTML5Backend}>
        <div className="flex flex-row gap-3">
          <div className="">
            <div className="w-56 flex flex-col overflow-y-auto overflow-x-hidden max-h-[48rem]">
              {sortedBoardItems.length === 0 ? (
                <div className="h-[100%] flex flex-col items-center justify-center">
                  <NoOrders title="Нет заказов" />
                </div>
              ) : (
                sortedBoardItems.map(({ interval, ordersByHour, intervalEnd }: ISortedBoardItem, i: number) => {
                  return (
                    <div key={interval}>
                      <Disclosure as="div" className="flex flex-col mb-2" defaultOpen={i === 0}>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="bg-yellow-200 relative focus:outline-0 flex mr-0.6 w-52">
                              <span className="px-4 py-2 text-left text-sm font-semibold text-gray-900 sm:px-6">
                                {`${moment(interval).format('HH:mm')} - ${intervalEnd.slice(0, 5)}`}
                                <span className="pl-2">({ordersByHour.length})</span>
                              </span>
                              <ChevronUpIcon
                                className={`${
                                  open ? 'rotate-180 transform' : ''
                                } h-5 w-5 absolute top-[23%] right-[3%]`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel static={open}>
                              {ordersByHour.map((order: OrderData) => {
                                return (
                                  <DraggableOrder
                                    key={order.id}
                                    orderNumber={`${order.orderNumber}`}
                                    onDrop={(bagId) => handleDropOrderInBag(order.id, bagId)}
                                    isSelected={selectedOrderId === order.id}
                                  >
                                    <div onClick={() => setSelectedOrderId(order.id)}>
                                      <Order order={order} isSelected={selectedOrderId === order.id} />
                                    </div>
                                  </DraggableOrder>
                                );
                              })}
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>
                  );
                })
              )}
            </div>
          </div>

          <div className="flex flex-grow flex-col">
            <OrderMap orders={orders} />
            <div className="col-span-2">
              <div className="container max-w-full relative overflow-clip">
                <div className="flex overflow-x-auto gap-8">
                  {bags.map((bag) => (
                    <DroppableOrderBag drivers={drivers} bag={bag} key={bag.id} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </DndProvider>

      {(boardDataLoading || driversDataLoading) && (
        <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center">
          <Spinner width="w-14" height="h-14" />
        </div>
      )}
    </>
  );
}

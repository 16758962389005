import React from 'react';
import { useTranslation } from 'react-i18next';

const OrdersTableHead = () => {
  const { t } = useTranslation();

  return (
    <thead className="bg-gray-50">
      <tr className="divide-x divide-gray-200">
        <th
          scope="col"
          className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
        >
          <span className="hidden lg:inline">{t('Order ID')}</span>
          <span className="lg:hidden">{t('Order')}</span>
        </th>
        <th
          scope="col"
          className="hidden xl:table-cell whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
        >
          {t('Order date')}
        </th>
        <th
          scope="col"
          className="hidden 2xl:table-cell whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
        >
          {t('Delivery date')}
        </th>
        <th
          scope="col"
          className="hidden 2xl:table-cell whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
        >
          {t('Interval date')}
        </th>
        <th
          scope="col"
          className=" min-w-[140px] whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
        >
          {t('Picker')}
        </th>
        <th scope="col" className="whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
          {t('Assembly quality')}
        </th>
        <th
          scope="col"
          className="hidden lg:table-cell whitespace-nowrap px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
        >
          {t('Client full name')}
        </th>
        <th
          scope="col"
          className=" whitespace-nowrap text-center px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
        >
          {t('Delivery status')}
        </th>
        <th scope="col" className=" text-center py-3.5 pl-3 pr-4 sm:pr-6 text-left text-sm font-semibold text-gray-900">
          {t('Payment')}
        </th>
      </tr>
    </thead>
  );
};

export { OrdersTableHead };

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';

interface Props {
  selectedPage: number;
  onPageSelect: (pageNumber: number) => void;
  itemsOnPage: number;
  totalCount: number;
}

const pageStyle =
  'bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium';
const activePageStyle =
  'z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium';

const PaginationList = ({ selectedPage, totalCount, itemsOnPage, onPageSelect }: Props) => {
  const lastPage = Math.ceil(totalCount / itemsOnPage);
  const { t } = useTranslation();
  const paginationButtons: string[] = useMemo(() => {
    const result: string[] = [];
    if (selectedPage > 1) {
      result.push('1');
      let firstMiddleButton = selectedPage - itemsOnPage;
      firstMiddleButton = Math.max(firstMiddleButton, 2);

      if (firstMiddleButton > 2) {
        result.push('...');
      }

      for (let i = firstMiddleButton; i < selectedPage; i += 1) {
        result.push(i.toString());
      }
    }

    result.push(selectedPage.toString());

    if (selectedPage < lastPage) {
      let lastMiddleButton = selectedPage + itemsOnPage;
      lastMiddleButton = Math.min(lastMiddleButton, lastPage - 1);

      for (let i = selectedPage + 1; i <= lastMiddleButton; i += 1) {
        result.push(i.toString());
      }

      if (lastPage - lastMiddleButton > 1) {
        result.push('...');
      }

      result.push(lastPage.toString());
    }
    return result;
  }, [itemsOnPage, lastPage, selectedPage]);

  const nextPage = () => {
    if (selectedPage < lastPage) onPageSelect(selectedPage + 1);
  };

  const previousPage = () => {
    if (selectedPage > 1) onPageSelect(selectedPage - 1);
  };

  const goToPage = (page: string) => {
    if (!Number.isNaN(Number(page))) onPageSelect(Number(page));
  };

  return (
    <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
      <div className="flex-1 flex justify-between sm:hidden">
        <button
          type="button"
          onClick={previousPage}
          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          {t('Previous')}
        </button>
        <button
          type="button"
          onClick={nextPage}
          className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        >
          {t('Next')}
        </button>
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
            {t('Showing')} <span className="font-medium">{(selectedPage - 1) * itemsOnPage}</span>
            <span> {t('to')} </span>
            <span className="font-medium">
              {selectedPage * itemsOnPage < totalCount ? selectedPage * itemsOnPage : totalCount}
            </span>
            <span> {t('of')} </span>
            <span className="font-medium">{totalCount}</span> {t('results')}
          </p>
        </div>
        <div>
          <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
            <button
              type="button"
              onClick={previousPage}
              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span className="sr-only">{t('Previous')}</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            {paginationButtons.map((button, index) => {
              return (
                <button
                  type="button"
                  key={index} // eslint-disable-line
                  onClick={() => goToPage(button)}
                  className={selectedPage.toString() === button ? activePageStyle : pageStyle}
                >
                  {button}
                </button>
              );
            })}
            <button
              type="button"
              onClick={nextPage}
              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span className="sr-only">{t('Next')}</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
};

export { PaginationList };

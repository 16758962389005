import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  OrderDeliveryStatus,
  OrderTypeEdge,
  useFinishOrderWithChangeMutation,
  useOrdersQuery,
} from '../../generated/graphql';
import { Spinner } from '../../components/UI';
import { OrdersTableDateHeader } from '../../components/ordersTable/ordersTableDateHeader/OrdersTableDateHeader';
import { Pagination } from '../../components/pagination/Pagination';
import { groupOrders } from '../../utils/orders';
import { NoOrders } from '../../components/board/noOrders/noOrders';
import { TableHeader, TableItemRow } from '../../components/UI/table';

const tableHeadTitles = [
  { id: 1, title: 'Order ID' },
  { id: 2, title: 'Client full name' },
  { id: 3, title: 'Requisites' },
  { id: 4, title: 'Transfer amount' },
  { id: 5, title: 'Driver' },
];

const countToDisplay = 50;
const POLL_INTERVAL = 20_000;

const PaymentChanges = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState<number>(1);

  const { data, loading, refetch, previousData } = useOrdersQuery({
    variables: {
      status: [OrderDeliveryStatus.A_5],
    },
    pollInterval: POLL_INTERVAL,
  });
  const [finishOrder] = useFinishOrderWithChangeMutation();

  const handleFinishOrder = useCallback(
    (id: string) => {
      finishOrder({
        onCompleted: (res) => {
          if (res.finishOrderWithChangeRequest.order) {
            refetch({
              status: [OrderDeliveryStatus.A_5],
            });
          }
        },
        variables: {
          orderId: id,
        },
      });
    },
    [finishOrder, refetch]
  );

  const totalCount = data?.orders?.totalCount;

  const orders = useMemo<{ date: string; ordersByDay: OrderTypeEdge[] }[]>(() => {
    const groupOrdersByDay = groupOrders(data?.orders.edges as Array<OrderTypeEdge>);

    return (
      groupOrdersByDay &&
      Object.keys(groupOrdersByDay).map((key) => ({
        date: key,
        ordersByDay: groupOrdersByDay[key],
      }))
    );
  }, [data]);

  const refetchOrders = useCallback(
    (pageNumber: number) => {
      setPage(pageNumber);
      refetch({
        first: countToDisplay,
        offset: countToDisplay * (pageNumber - 1),
      });
    },
    [refetch, setPage]
  );

  if (loading && !previousData) {
    return (
      <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center">
        <Spinner width="w-14" height="h-14" />
      </div>
    );
  }

  return !orders.length ? (
    <div className="h-[80vh] my-auto flex items-center justify-center flex-col">
      <NoOrders title="Нет сдач" stylesImg="w-[6.25rem] h-[6.25rem]" />
    </div>
  ) : (
    <div className="px-4 sm:px-6 lg:px-8">
      <h2 className="text-5xl font-normal leading-normal mt-0 mb-2 text-black">{t('Change calculation')}</h2>
      <Pagination
        toPage={refetchOrders}
        activePage={page}
        rangeStart={(countToDisplay * (page - 1) === 0 ? 1 : countToDisplay * (page - 1) + 1)!}
        rangeEnd={countToDisplay * page > totalCount! ? totalCount! : countToDisplay * page}
        totalCount={totalCount!}
        lastPage={Math.ceil(totalCount! / countToDisplay)}
      />
      {orders.map(({ date, ordersByDay }, i: number) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <div key={i}>
            <OrdersTableDateHeader date={date} />
            <div className="mt-5 mb-12 flex flex-col">
              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block py-2 align-middle md:px-6 lg:px-8  align-middle md:px-6 lg:px-8 w-full">
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <div className="bg-white">
                      <TableHeader isHaveActionButton titles={tableHeadTitles} />
                      <div>
                        {ordersByDay.map((item: OrderTypeEdge) => {
                          return (
                            <TableItemRow
                              key={item.node.orderNumber}
                              itemValues={[
                                {
                                  type: 'string',
                                  value: item.node.orderNumber,
                                },
                                {
                                  type: 'string',
                                  value: item.node.customerFullname,
                                },
                                {
                                  type: 'string',
                                  value: item.node.changePaymentInfo,
                                },
                                {
                                  type: 'string',
                                  value: item.node.changeAmount,
                                },
                                {
                                  type: 'string',
                                  value: item.node.orderinbag?.ordersBag?.driver
                                    ? `${item.node.orderinbag.ordersBag.driver.user.firstName} ${item.node.orderinbag.ordersBag.driver.user.lastName}`
                                    : '-',
                                },
                              ]}
                              button={{
                                title: 'Change sent',
                                handle: () => {
                                  handleFinishOrder(String(item.node.orderNumber));
                                },
                              }}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
      <Pagination
        toPage={refetchOrders}
        activePage={page}
        rangeStart={(countToDisplay * (page - 1) === 0 ? 1 : countToDisplay * (page - 1) + 1)!}
        rangeEnd={countToDisplay * page > totalCount! ? totalCount! : countToDisplay * page}
        totalCount={totalCount!}
        lastPage={Math.ceil(totalCount! / countToDisplay)}
      />
    </div>
  );
};

export { PaymentChanges };

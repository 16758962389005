import React from 'react';
import { useTranslation } from 'react-i18next';

interface TableItemProps {
  key: string | number;
  onRowClick?: () => void;
  itemValues: {
    value: string | number;
    type: 'string';
  }[];
  button?: {
    title: string;
    handle: () => void;
    className?: string;
  };
}

const TableItemRow = ({ key, itemValues, button, onRowClick }: TableItemProps) => {
  const { t } = useTranslation();

  return (
    <div
      key={key}
      onClick={() => onRowClick && onRowClick()}
      className={`grid grid-columns-${
        button ? itemValues.length + 1 : itemValues.length
      } hover:bg-yellow-100 cursor-pointer border-t border-gray-300 items-center`}
    >
      {/* length + 1 FOR ACTION BUTTON */}
      {itemValues.map((item, index) => {
        return (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={`${key}-${index}`}
            className={`whitespace-normal break-all px-3 py-4 text-sm
							${index === 0 ? 'font-medium text-gray-900 sm:pl-6' : 'text-gray-500'}
							${index === (button ? itemValues.length - 1 : itemValues.length) ? 'sm:pr-6' : ''}`}
          >
            {item.type === 'string' && item.value}
          </div>
        );
      })}
      {button && (
        <div className="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6">
          <button
            onClick={(e) => {
              e.stopPropagation();
              button.handle();
            }}
            className={
              button.className ??
              'bg-transparent hover:bg-amber-200 text-black font-semibold hover:text-black py-2 px-4 border border-amber-400 hover:border-transparent focus:outline-none rounded'
            }
          >
            {t(button.title)}
          </button>
        </div>
      )}
    </div>
  );
};

export { TableItemRow };

import React, { useEffect, useReducer, useState } from 'react';
import { TokenAuthMutation, UserRegisterMutation, UserType } from '../generated/graphql';

interface ActionType {
  type: 'LOGIN' | 'LOGOUT' | 'REGISTER' | 'SET_USER';
  payload?: StateType;
  // TokenAuthMutation & UserRegisterMutation;
}

interface StateType {
  user?: Partial<UserType> | null;
}

export interface AuthContextType extends StateType {
  login: (tokenData: TokenAuthMutation) => void;
  register: (userData: UserRegisterMutation) => void;
  logout: () => void;
  setUser: (user: Partial<UserType>) => void;
  isFetchingFromStorage: boolean;
}

// eslint-disable-next-line consistent-return
const getInitialStateFromLocalStorage = (dispatch: React.Dispatch<ActionType>) => {
  const value = localStorage.getItem('user');
  if (value) {
    const user: Partial<UserType> = JSON.parse(value);
    dispatch({
      type: 'LOGIN',
      payload: { user },
    });
    return user;
  }
};

const initialState: StateType = {
  user: null,
};

const AuthContext = React.createContext<AuthContextType>({
  /* eslint-disable @typescript-eslint/no-unused-vars */
  user: null,
  login: (tokenData: TokenAuthMutation) => {},
  register: (userData: UserRegisterMutation) => {},
  logout: () => {},
  setUser: (user: Partial<UserType>) => {},
  isFetchingFromStorage: true,
});

const authReducer = (state: StateType, action: ActionType): StateType => {
  switch (action.type) {
    case 'REGISTER': {
      const { user } = action.payload!;
      return {
        ...state,
        user,
      };
    }
    case 'LOGIN': {
      const { user } = action.payload!;
      return {
        ...state,
        user,
      };
    }
    case 'LOGOUT': {
      return initialState;
    }
    case 'SET_USER': {
      const { user } = action.payload!;
      return {
        ...state,
        user,
      };
    }
    default:
      return state;
  }
};

const AuthProvider = (props: any) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const [isFetchingFromStorage, setIsFetchingFromStorage] = useState(true);

  useEffect(() => {
    getInitialStateFromLocalStorage(dispatch);
    setIsFetchingFromStorage(false);
  }, []);

  const login = async (tokenData: TokenAuthMutation) => {
    const { user, token } = tokenData.tokenAuth;

    dispatch({
      type: 'LOGIN',
      payload: { user: user as UserType },
    });

    localStorage.setItem('user', JSON.stringify(user));

    localStorage.setItem('token', token);
  };

  const logout = async () => {
    dispatch({
      type: 'LOGOUT',
    });
    localStorage.removeItem('token');
    localStorage.removeItem('user');
  };

  const register = (userData: UserRegisterMutation) => {
    const { user } = userData.userRegister;
    dispatch({
      type: 'REGISTER',
      payload: { user },
    });
  };

  const setUser = async (user: Partial<UserType>) => {
    localStorage.setItem('user', JSON.stringify(user));

    dispatch({
      type: 'SET_USER',
      payload: { user },
    });
  };

  /* eslint-disable react/jsx-no-constructed-context-values */
  return (
    <AuthContext.Provider
      value={{
        user: state.user,
        login,
        logout,
        register,
        setUser,
        isFetchingFromStorage,
      }}
      {...props}
    />
  );
};

const useAuth = () => React.useContext(AuthContext);

export { AuthProvider, useAuth };

import React from 'react';
import moment from 'moment';
import { BagData, ID } from '../../../providers/board/boardTypes';
import { BoardCard } from '../boardCard/BoardCard';
import { OrderDeliveryStatus } from '../../../generated/graphql';

type BagOrder = BagData['activeOrdersBag']['orders'][number];

interface Props {
  order: BagOrder;
  onRemoveOrder?: (orderId: ID) => void;
  isSelected?: boolean;
}

function OrderInfo({ order }: { order: BagOrder }) {
  return (
    <>
      <div>{order.totalCost}₽</div>
      <div>{order.orderStatus}</div>
      <div>{order.deliveryStatus}</div>
      <div>{order.address.fullAddress}</div>
    </>
  );
}

const Order: React.FC<Props> = ({ order, isSelected = false, onRemoveOrder }) => {
  return (
    <BoardCard
      onRemoveOrder={() => onRemoveOrder?.(order.id)}
      isInBag={!!order.orderinbag}
      id={`${order.orderNumber}`}
      index={order.orderinbag ? order.orderinbag.priority + 1 : ''}
      isSelected={isSelected}
      active={order.deliveryStatus === OrderDeliveryStatus.A_2}
      dimmed={order.deliveryStatus !== OrderDeliveryStatus.A_1 && order.deliveryStatus !== OrderDeliveryStatus.A_2}
      popupContent={
        <div className="bg-white p-3">
          <OrderInfo order={order} />
        </div>
      }
      customerFullName={order.customerFullname}
      time={`${moment(`${order.deliveryDate} ${order.deliveryIntervalStart}`).format('HH:mm')} - 
                          ${moment(`${order.deliveryDate} ${order.deliveryIntervalEnd}`).format('HH:mm')}
                    `}
    />
  );
};

export { Order };

import React, { createContext, useContext, useMemo, useState } from 'react';
import { useBoardOrdersBagsDriversData } from './boardHooks';
import { ID, OrderData } from './boardTypes';

const POLL_INTERVAL = 10_000;

const BoardStateContext = createContext<{
  selectedOrderId: ID | null;
  selectedBagId: ID | null;
  setSelectedOrderId: (id: ID) => void;
}>(undefined!);

export const BoardStateProvider = ({ children }: any) => {
  const { orders } = useBoardOrdersBagsDriversData(POLL_INTERVAL);

  const [selectedOrderId, setSelectedOrderId] = useState<OrderData['id'] | null>(null);

  const selectedBagId = useMemo(() => {
    return orders?.find(({ id }) => id === selectedOrderId)?.orderinbag?.ordersBag.bag?.id ?? null;
  }, [selectedOrderId, orders]);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <BoardStateContext.Provider value={{ selectedOrderId, selectedBagId, setSelectedOrderId }}>
      {children}
    </BoardStateContext.Provider>
  );
};

export const useBoardStateData = () => useContext(BoardStateContext);
